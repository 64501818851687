import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { PhaseArrowIcon } from '../../components/Icons';

interface PhaseProps {
  phase: number;
  title: string | JSX.Element;
  content: string | JSX.Element;
  current?: boolean;
  showArrow?: boolean;
}

function Phase({ phase, title, content, current = false, showArrow = true }: PhaseProps): JSX.Element {
  return (
    <Box sx={[!current && { opacity: 0.5 }]}>
      <Grid container spacing={1} alignItems="center" sx={{ mb: 1 }}>
        <Grid item>
          <Typography
            variant="h3"
            color={current ? '#E7EDED' : 'text.secondary'}
            sx={{ fontSize: { xs: '3rem', md: '1.875rem', lg: '3rem' } }}
          >
            Phase {phase}
          </Typography>
        </Grid>
        <Grid item>
          <PhaseArrowIcon
            sx={[
              { fontSize: { xs: '3rem', lg: '4rem' } },
              current && { opacity: 0.5 },
              !showArrow && { opacity: 0 },
              {
                opacity: { xs: 0, md: 0.5 },
              },
            ]}
          />
        </Grid>
      </Grid>
      <Typography sx={{ textTransform: 'uppercase', mb: 1 }}>
        <strong>{title}</strong>
      </Typography>
      <Typography sx={{ fontWeight: 500, lineHeight: 2.4 }}>{content}</Typography>
    </Box>
  );
}

export default function Roadmap(): JSX.Element {
  return (
    <Box sx={{ bgcolor: '#A2B2B4', py: 7, color: 'text.secondary' }} id="roadmap">
      <Container>
        <Typography variant="h3" sx={{ mb: 4 }}>
          <strong>Roadmap</strong>
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Phase phase={1} current title="Basecamp" content="First 10 000 origins released Mine, Explore and Hack" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Phase phase={2} title="Lands" content="Acquire Lands NFT Upgrades" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Phase phase={3} title="Alliances" content="Invite other players Earn and Defend together" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Phase showArrow={false} phase={4} title="Manual control" content="Manually controll your characters" />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
