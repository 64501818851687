import React from 'react';
import { alpha, Box, Button, Typography } from '@mui/material';
import BannerPoster from '../../assets/images/banner-poster.png';
import BannerLogo from '../../assets/images/banner-logo.svg';

export default function Banner(): JSX.Element {
  return (
    <Box
      sx={{
        background: `url(${BannerPoster}) no-repeat center center/cover`,
        height: 735,
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Box
        sx={{
          width: { xs: 1, md: 0.6, lg: 0.44 },
          px: 3.75,
          py: 3,
          bgcolor: (theme) => alpha(theme.palette.primary.light, 0.4),
        }}
      >
        <Box sx={{ height: 3, bgcolor: 'primary.main', mb: 2.75 }} />
        <Box component="img" src={BannerLogo} sx={{ width: 1, maxWidth: 402, mb: { xs: 10, sm: 16 } }} />
        <Typography
          variant="h1"
          color="text.secondary"
          sx={{ maxWidth: 400, mb: 2.75, lineHeight: 1, fontSize: { xs: '3rem', sm: '4.0265rem' } }}
        >
          <strong>Choose your path: Mine, Explore and Hack</strong>
        </Typography>
        <Typography
          color="text.secondary"
          sx={{ textTransform: 'uppercase', maxWidth: 400, lineHeight: 1.875, mb: 2.75, wordSpacing: '0.4em' }}
        >
          <strong>NFT P2E SPACE THEMED Wolf Game derivative on Binance Network</strong>
        </Typography>
        <Button variant="outlined" color="secondary" size="large">
          <strong>Enter</strong>
        </Button>
      </Box>
    </Box>
  );
}
