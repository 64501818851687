import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

import { ReactComponent as Discord } from '../../assets/icons/discord.svg';
import { ReactComponent as Medium } from '../../assets/icons/medium.svg';
import { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import { ReactComponent as Youtube } from '../../assets/icons/youtube.svg';
import { ReactComponent as Telegram } from '../../assets/icons/telegram.svg';
import { ReactComponent as Spotify } from '../../assets/icons/spotify.svg';
import { ReactComponent as PhaseArrow } from '../../assets/icons/phase-arrow.svg';

export function DiscordIcon(props: SvgIconProps): JSX.Element {
  return <SvgIcon component={Discord} viewBox="0 0 29 23" {...props} />;
}

export function MediumIcon(props: SvgIconProps): JSX.Element {
  return <SvgIcon component={Medium} viewBox="0 0 29 23" {...props} />;
}

export function TwitterIcon(props: SvgIconProps): JSX.Element {
  return <SvgIcon component={Twitter} viewBox="0 0 29 23" {...props} />;
}

export function YouTubeIcon(props: SvgIconProps): JSX.Element {
  return <SvgIcon component={Youtube} viewBox="0 0 37 27" {...props} />;
}

export function TelegramIcon(props: SvgIconProps): JSX.Element {
  return <SvgIcon component={Telegram} viewBox="0 0 29 25" {...props} />;
}

export function SpotifyIcon(props: SvgIconProps): JSX.Element {
  return <SvgIcon component={Spotify} viewBox="0 0 28 27" {...props} />;
}

export function PhaseArrowIcon(props: SvgIconProps): JSX.Element {
  return <SvgIcon component={PhaseArrow} viewBox="0 0 71 23" {...props} />;
}
