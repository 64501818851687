import React from 'react';
import { Box, Container, Grid, SxProps, Typography } from '@mui/material';

function Community({ sx, value, name }: { sx?: SxProps; value: number; name: string | JSX.Element }): JSX.Element {
  return (
    <Box sx={{ pt: 1, pr: 1, pl: 1.5, borderColor: 'secondary.main', height: 1, ...sx }}>
      <Grid container spacing={{ xs: 0, lg: 1 }} flexWrap="nowrap" sx={{ flexDirection: { xs: 'column', lg: 'row' } }}>
        <Grid item sx={{ flex: 1 }}>
          <Typography variant="h3" sx={{ fontSize: { xs: '1.45rem', sm: '1.875rem', md: '3rem' } }}>
            {value}
          </Typography>
        </Grid>
        <Grid item sx={{ width: { xs: 1, lg: 102 } }}>
          <Typography
            sx={{
              textAlign: { xs: 'left', lg: 'right' },
              textTransform: 'uppercase',
              fontSize: { xs: '0.75rem', sm: '1rem' },
            }}
          >
            <strong>{name}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function CommunityGrowth(): JSX.Element {
  return (
    <Box sx={{ bgcolor: 'primary.main', pt: 3.75, pb: 4, color: 'primary.contrastText' }}>
      <Container>
        <Typography sx={{ textTransform: 'uppercase', mb: 1.5 }}>
          <strong>The Community is Growing</strong>
        </Typography>
        <Grid container sx={{ borderTop: 3, borderColor: 'secondary.main' }}>
          <Grid item xs={6} sm={3}>
            <Community
              value={10000}
              sx={{
                borderRight: 3,
              }}
              name="NFTs Minted"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Community
              value={10000}
              sx={{
                borderRight: { xs: 0, sm: 3 },
              }}
              name="Discord Member"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Community
              value={10000}
              sx={{
                borderRight: 3,
              }}
              name="Twitter Followers"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Community value={10000} name="Wallet Holders" sx={{}} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
