import React from 'react';
import { alpha, Box, Container, Grid, Typography } from '@mui/material';

export default function Instructor(): JSX.Element {
  return (
    <Box sx={{ py: 12 }} id="story">
      <Container>
        <Grid
          container
          spacing={{ xs: 8, md: 16 }}
          alignItems="center"
          sx={{ flexDirection: { xs: 'column', md: 'row' } }}
        >
          <Grid item sx={{ flex: { xs: 1, md: '1 1 559px' } }}>
            <Typography variant="h3">
              The planet has deteriorated beyond hope and humans have been forced into space.
            </Typography>
          </Grid>
          <Grid item sx={{ flex: { xs: 1, md: '1 1 459px' } }}>
            <Box
              sx={{
                height: 242,
                overflowY: 'scroll',
                scrollbarWidth: 'thin',
                scrollbarColor: (theme) => `${theme.palette.primary.main} ${alpha(theme.palette.primary.main, 0.15)}`,
                '&': {
                  '::-webkit-scrollbar': {
                    width: 16,
                  },
                  '::-webkit-scrollbar-track': {
                    background: (theme) => alpha(theme.palette.primary.main, 0.15),
                  },
                  '::-webkit-scrollbar-thumb': {
                    backgroundColor: 'primary.main',
                  },
                  ' p': {
                    mb: 2,
                  },
                },
              }}
            >
              <Typography component="p" sx={{ textTransform: 'uppercase', wordSpacing: '0.4em' }}>
                <strong>ASH is now in high demand</strong>
              </Typography>
              <Typography component="p" color="primary.light">
                The planet has deteriorated beyond hope and humans have been forced into space.
              </Typography>
              <Typography component="p" color="primary.light">
                The navigation design should communicate the relationship between the links it contains so that users
                understand the options they have for navigating the site.
              </Typography>
              <Typography component="p" color="primary.light">
                The navigation design should communicate the relationship between the links it contains so that users
                understand the options they have for navigating the site.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
