import { createTheme } from '@mui/material';
import ButtonArrow from './assets/icons/button-arrow.svg';

export const primaryColor = '#7AF1BD';
export const primaryLightColor = '#C4F0DD';
export const secondaryColor = '#25201C';

export const defaultTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: primaryColor,
      light: primaryLightColor,
      contrastText: secondaryColor,
    },
    secondary: {
      main: secondaryColor,
      contrastText: '#FFFFFF',
    },
    background: {
      paper: secondaryColor,
      default: secondaryColor,
    },
    text: {
      primary: primaryColor,
      secondary: secondaryColor,
    },
  },
  typography: {
    fontSize: 18,
    fontFamily: '"Space Grotesk", "Helvetica", "Arial", "sans-serif"',
    h1: {
      fontSize: '4.0265rem',
    },
    h3: {
      fontSize: '3rem',
    },
    h5: {
      fontSize: '1.875rem',
    },
    body1: {
      fontSize: '1.125rem',
    },
    caption: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        img: {
          verticalAlign: 'middle',
        },
        svg: {
          verticalAlign: 'middle',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          letterSpacing: 0,
          borderRadius: 0,
          position: 'relative',
          '&': {
            ':hover': {
              '&:after': {
                transform: 'translate(0, 0)',
                opacity: 1,
              },
            },
            ':after': {
              content: '""',
              display: 'block',
              width: 9,
              height: 9,
              position: 'absolute',
              color: 'inherit',
              mask: `url(${ButtonArrow}) no-repeat center center/100%`,
              backgroundColor: primaryColor,
              top: 5,
              right: 5,
              transform: 'translate(-2px, 2px)',
              opacity: 0,
              transition: 'all 0.2s ease-in-out',
            },
          },
        },
        sizeLarge: {
          minWidth: 157,
          fontSize: '1rem',
          padding: '10px 22px',
        },
        containedPrimary: {
          '&': {
            ':hover': {
              backgroundColor: primaryColor,
            },
            ':after': {
              backgroundColor: secondaryColor,
            },
          },
        },
        text: {
          '&': {
            ':after': {
              display: 'none',
            },
          },
        },
        outlined: {
          borderWidth: 3,
          '&': {
            ':hover': {
              borderWidth: 3,
              backgroundColor: 'transparent',
            },
            ':after': {
              backgroundColor: secondaryColor,
            },
          },
        },
        outlinedPrimary: {
          borderColor: primaryColor,
          '&': {
            ':after': {
              backgroundColor: primaryColor,
            },
          },
        },
        outlinedSecondary: {
          borderColor: secondaryColor,
          '&': {
            ':after': {
              backgroundColor: secondaryColor,
            },
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'unset',
        },
      },
    },
  },
});
