import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { DiscordIcon, MediumIcon, SpotifyIcon, TelegramIcon, TwitterIcon, YouTubeIcon } from '../Icons';

export default function Socials({ size = 'medium' }: { size?: 'small' | 'medium' }): JSX.Element {
  return (
    <Grid
      container
      spacing={1.75}
      alignItems="center"
      sx={{
        '& a': { color: 'text.primary', p: 0 },
        '& svg': { height: 'auto', fontSize: 'inherit' },
      }}
    >
      <Grid item>
        <IconButton href="/" sx={{ fontSize: size === 'medium' ? 28 : 16 }}>
          <TwitterIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton href="/" sx={{ fontSize: size === 'medium' ? 28 : 16 }}>
          <DiscordIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton href="/" sx={{ fontSize: size === 'medium' ? 28 : 16 }}>
          <TelegramIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton href="/" sx={{ fontSize: size === 'medium' ? 28 : 16 }}>
          <MediumIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton href="/" sx={{ fontSize: size === 'medium' ? 27 : 16 }}>
          <SpotifyIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton href="/" sx={{ fontSize: size === 'medium' ? 36 : 21 }}>
          <YouTubeIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
