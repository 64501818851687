import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import TokenmicsImage from '../../assets/images/tokenomics.png';

export default function Tokenomics(): JSX.Element {
  return (
    <Box sx={{ pt: 7, pb: 10, bgcolor: '#A2B2B4' }} id="tokenomics">
      <Container>
        <Typography color="text.secondary" variant="h3" sx={{ mb: 4 }}>
          Tokenomics
        </Typography>
        <Box component="img" sx={{ width: 1, mb: 3 }} src={TokenmicsImage} />
        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <Button variant="outlined" color="secondary" size="large" sx={{ width: 226, px: 0 }}>
              <strong>Download whitepaper</strong>
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary" size="large" sx={{ width: 226, px: 0 }}>
              <strong>Download litepaper</strong>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
