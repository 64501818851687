import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import FooterLogo from '../../assets/images/footer-logo.svg';
import Socials from '../Socials/Socials';
import BinanceLogo from '../../assets/images/binance-logo.svg';
import PancakeLogo from '../../assets/images/pancake-logo.svg';

export default function Footer(): JSX.Element {
  return (
    <Box sx={{ pt: 5 }}>
      <Container>
        <Box component="img" src={FooterLogo} sx={{ width: 162, mb: 5 }} />
        <Grid container sx={{ borderTop: 3, borderBottom: 3, flexDirection: { xs: 'column', md: 'row' } }}>
          <Grid item sx={{ flex: 1 }}>
            <Box sx={{ p: 4, pl: 0, pr: { xs: 0, md: 4 }, borderRight: { xs: 0, md: 3 } }}>
              <Grid container spacing={{ xs: 2, lg: 4 }} sx={{ flexDirection: { xs: 'column', lg: 'row' } }}>
                <Grid item>
                  <Typography color="primary.light" sx={{ width: 264 }}>
                    Subscribe to stay tuned for news and latest updates
                  </Typography>
                </Grid>
                <Grid item sx={{ flex: 1 }}>
                  <Grid container spacing={2} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
                    <Grid item sx={{ flex: 1 }}>
                      <Box
                        component="input"
                        sx={{
                          border: 'none',
                          outline: 'none',
                          width: 1,
                          borderBottom: 3,
                          borderColor: '#A2B2B4',
                          height: 54,
                          bgcolor: 'transparent',
                          color: 'text.primary',
                          fontSize: 18,
                          fontWeight: 700,
                          '&': {
                            '::placeholder': {
                              color: 'primary.light',
                            },
                          },
                        }}
                        placeholder="EMAIL"
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" size="large" sx={{ width: 226 }}>
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ pl: { xs: 0, md: 6 }, pb: { xs: 4, md: 0 }, display: 'flex', alignItems: 'center', height: 1 }}>
              <Socials />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
          <Grid item>
            <Box sx={{ py: { xs: 2, sm: 6 }, px: { xs: 0, sm: 3 }, borderRight: { xs: 0, sm: 3 } }}>
              <Box component="img" sx={{ width: 126, height: 24, bgcolor: 'primary.light' }} />
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ py: { xs: 2, sm: 6 }, px: { xs: 0, sm: 3 }, borderRight: { xs: 0, sm: 3 } }}>
              <Box component="img" sx={{ width: 117 }} src={BinanceLogo} />
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ py: { xs: 2, sm: 6 }, px: { xs: 0, sm: 3 } }}>
              <Box component="img" sx={{ width: 168 }} src={PancakeLogo} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
