import React from 'react';
import { Box, Container, Grid, SxProps, Typography } from '@mui/material';

function GameStep({ step, title, content }: { step: number; title: string; content: string }): JSX.Element {
  return (
    <>
      <Box sx={{ width: 1, height: 160, bgcolor: '#D5D2C133' }} />
      <Box sx={{ p: 2, pl: 1.5 }}>
        <Grid
          container
          spacing={{ xs: 4, md: 2 }}
          wrap="nowrap"
          sx={{ flexDirection: { xs: 'row', md: 'column', lg: 'row' } }}
        >
          <Grid item>
            <Typography variant="h1" component="p" sx={{ lineHeight: 1 }}>
              {step}
            </Typography>
          </Grid>
          <Grid item>
            <Typography color="text.secondary" sx={{ whiteSpace: '0.4em' }}>
              <strong>{title}</strong>
            </Typography>
            <Typography color="text.secondary" sx={{ fontWeight: 500 }}>
              {content}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

function GameInstruction({
  title,
  content,
  sx,
}: {
  title: JSX.Element | string;
  content: JSX.Element | string;
  sx?: SxProps;
}): JSX.Element {
  return (
    <Box sx={sx}>
      <Box sx={{ width: 1, maxWidth: 361, height: 361, bgcolor: '#fff', mb: 3 }} />
      <Box sx={{ borderLeft: 3, pl: 1.5 }}>
        <Typography variant="h3" sx={{ mb: 1 }}>
          <strong>{title}</strong>
        </Typography>
        <Typography color="text.secondary" sx={{ fontWeight: 500 }}>
          {content}
        </Typography>
      </Box>
    </Box>
  );
}

export default function HowToPlay(): JSX.Element {
  return (
    <Box sx={{ backgroundColor: '#A2B2B4', pt: 7, pb: 10 }} id="how-to-play">
      <Container>
        <Typography variant="h3" color="text.secondary" sx={{ mb: 4 }}>
          How to play Meta Ash
        </Typography>
        <Grid
          container
          sx={{
            border: 3,
            mb: 5,
            '&': { '> div': { flex: 1, borderRight: 3, '&:last-of-type': { borderRight: 0 } } },
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Grid item>
            <GameStep
              step={1}
              title="Buy Origin"
              content="Deposit your wallet with BUSD, connect the wallet in game and buy Origin in Marketplace."
            />
          </Grid>
          <Grid item>
            <GameStep
              step={2}
              title="Incubate"
              content="Decode the stored information inside the Origin and discover your character."
            />
          </Grid>
          <Grid item>
            <GameStep
              step={3}
              title="Choose action"
              content="Choose the action with your character. Higher risk means higher rewards."
            />
          </Grid>
          <Grid item>
            <GameStep
              step={4}
              title="Harvest $ASH"
              content="With human harvest your $ASH regularly to avoid the risk of being hacked."
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          justifyContent="space-between"
          sx={{ '& > div': { flex: 1 }, flexDirection: { xs: 'column', sm: 'row' } }}
        >
          <Grid item>
            <GameInstruction
              sx={{ mt: { xs: 0, sm: 28 } }}
              title="Mine"
              content={`
                Use your on-board mining module to get stable source of $ASH with minimal risk but long harvest period
              `}
            />
          </Grid>
          <Grid item>
            <GameInstruction
              sx={{ mt: { xs: 0, sm: 12 } }}
              title="Explore"
              content={`
                You can explore distant deposits of $ASH with the help of droids.
                They use 40% of acquired $ASH as the source of energy.
              `}
            />
          </Grid>
          <Grid item>
            <GameInstruction
              title="Hack"
              content={`
                Keen to take the risk? With the help of XYZ you can hack the $ASH infrastructure.
                There is 40% chance of being compromised and loosing it.
              `}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
