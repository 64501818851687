import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';

function ProcessBar({ title, value }: { value?: number; title?: string }): JSX.Element {
  return (
    <Box sx={{ height: 28, position: 'relative', borderTop: 1, borderBottom: 1 }}>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          height: 1,
          width: value ?? 0,
          bgcolor: 'primary.main',
          overflow: 'hidden',
        }}
      >
        {title ? (
          <Typography
            variant="caption"
            color="primary.contrastText"
            sx={{ px: 1, py: 0.75, lineHeight: '26px', whiteSpace: 'nowrap' }}
          >
            <strong>{title}</strong>
          </Typography>
        ) : undefined}
      </Box>
      {title ? (
        <Typography variant="caption" sx={{ px: 1, py: 0.75, lineHeight: '26px' }}>
          <strong>{title}</strong>
        </Typography>
      ) : undefined}
    </Box>
  );
}

export default function PriceTimeline(): JSX.Element {
  return (
    <Box sx={{ bgcolor: 'background.default', pt: 7, pb: 10 }}>
      <Container>
        <Typography variant="h3" sx={{ mb: 4.25 }}>
          NFT price timeline
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ py: 0.75, px: 1, width: 0.18 }}>
            <Typography variant="caption" component="p" sx={{ fontWeight: 500, mb: 0.25 }}>
              6 500x
            </Typography>
            <Typography variant="h5" sx={{ fontSize: { xs: '1.225rem', md: '1.875rem' } }}>
              150
              <Typography component="span" sx={{ fontSize: 15, verticalAlign: 'top', fontWeight: 500 }}>
                BUSD
              </Typography>
            </Typography>
          </Box>
          <Box sx={{ width: 0.32 }}>
            <Box sx={{ display: 'flex', height: 1 }}>
              <Box sx={{ width: 0.5, borderLeft: 1, py: 0.75, px: 1 }}>
                <Typography variant="caption" component="p" sx={{ fontWeight: 500, textTransform: 'uppercase' }}>
                  NFT Price
                </Typography>
                <Typography variant="h5" sx={{ fontSize: { xs: '1.225rem', md: '1.875rem' } }}>
                  199
                  <Typography component="span" sx={{ fontSize: 15, verticalAlign: 'top', fontWeight: 500 }}>
                    BUSD
                  </Typography>
                </Typography>
              </Box>
              <Box sx={{ flex: 1, borderLeft: 1, py: 0.75, px: 1 }}>
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ fontWeight: 500, textTransform: 'uppercase', mb: { xs: 0, md: 1 } }}
                >
                  Remaining
                </Typography>
                <Typography
                  color="primary.contrastText"
                  component="span"
                  sx={{
                    lineHeight: '28px',
                    px: 0.625,
                    bgcolor: 'primary.main',
                    borderRadius: 1.5,
                    fontSize: { xs: '0.75rem', md: '1.125rem' },
                  }}
                >
                  <strong>8999x</strong>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: 0.5 }}>
            <Box sx={{ display: 'flex', height: 1 }}>
              <Box sx={{ py: 0.75, px: 1, width: 0.3333, borderLeft: 1 }}>
                <Typography variant="caption" component="p" sx={{ fontWeight: 500, mb: 0.25 }}>
                  10 000x
                </Typography>
                <Typography variant="h5" sx={{ fontSize: { xs: '1.225rem', md: '1.875rem' } }}>
                  1000
                  <Typography component="span" sx={{ fontSize: 15, verticalAlign: 'top', fontWeight: 500 }}>
                    ASH
                  </Typography>
                </Typography>
              </Box>
              <Box sx={{ py: 0.75, px: 1, width: 0.3333, borderLeft: 1 }}>
                <Typography variant="caption" component="p" sx={{ fontWeight: 500, mb: 0.25 }}>
                  20 000x
                </Typography>
                <Typography variant="h5" sx={{ fontSize: { xs: '1.225rem', md: '1.875rem' } }}>
                  1200
                  <Typography component="span" sx={{ fontSize: 15, verticalAlign: 'top', fontWeight: 500 }}>
                    ASH
                  </Typography>
                </Typography>
              </Box>
              <Box sx={{ py: 0.75, px: 1, width: 0.3333, borderLeft: 1 }}>
                <Typography variant="caption" component="p" sx={{ fontWeight: 500, mb: 0.25 }}>
                  10 000x
                </Typography>
                <Typography variant="h5" sx={{ fontSize: { xs: '1.225rem', md: '1.875rem' } }}>
                  1600
                  <Typography component="span" sx={{ fontSize: 15, verticalAlign: 'top', fontWeight: 500 }}>
                    ASH
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Grid container sx={{ lineHeight: 1 }}>
          <Grid item sx={{ width: 0.18 }}>
            <ProcessBar value={0.1} title="Whitelist" />
          </Grid>
          <Grid item sx={{ width: 0.32, borderLeft: 1 }}>
            <ProcessBar value={0} title="Public Sale" />
          </Grid>
          <Grid item sx={{ width: 0.5, borderLeft: 1 }}>
            <ProcessBar />
          </Grid>
        </Grid>
        <Grid container sx={{ mb: 4.25 }}>
          <Grid item sx={{ width: 0.5 }}>
            <Box sx={{ py: 0.75 }}>
              <Typography>Phase 1: GEN 0</Typography>
            </Box>
          </Grid>
          <Grid item sx={{ width: 0.5 }}>
            <Box sx={{ py: 0.75, pl: 1, borderLeft: 1 }}>
              <Typography>Phase 2: GEN 1</Typography>
            </Box>
          </Grid>
        </Grid>
        <Button variant="outlined" size="large">
          More stats
        </Button>
      </Container>
    </Box>
  );
}
