import React from 'react';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import Banner from './Banner';
import Instructor from './Instructor';
import HowToPlay from './HowToPlay';
import IncubateYourNFTs from './IncubateYourNFTs';
import PriceTimeline from './PriceTimeline';
import CommunityGrowth from './CommunityGrowth';
import Roadmap from './Roadmap';
import Tokenomics from './Tokenomics';
import FAQ from './FAQ';
import News from './News';

export default function Home(): JSX.Element {
  return (
    <>
      <Banner />
      <Instructor />
      <HowToPlay />
      <IncubateYourNFTs />
      <PriceTimeline />
      <CommunityGrowth />
      <Roadmap />
      <Tokenomics />
      <FAQ />
      <Box sx={{ py: 10, bgcolor: 'primary.main' }}>
        <Container>
          <Grid
            container
            spacing={4}
            alignItems="flex-start"
            flexWrap="nowrap"
            sx={{ color: 'text.secondary', flexDirection: { xs: 'column', md: 'row' } }}
          >
            <Grid item sx={{ width: 264 }}>
              <Typography component="p">
                <strong>Crypto guy</strong>
              </Typography>
              <Typography>Coin Bureau</Typography>
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <Typography variant="h3">
                “Amazing experience i love it a lot. Thanks to the team that dreams come true, great! I recommend”
              </Typography>
            </Grid>
            <Grid item sx={{ alignSelf: 'flex-end', width: 166, textAlign: 'right' }}>
              <Button color="secondary">
                Next <EastIcon sx={{ ml: 2 }} />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <News />
    </>
  );
}
