import React, { useState } from 'react';
import { Box, Button, Container, Drawer, Grid, IconButton, Link, List, ListItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HeaderLogo from '../../assets/images/header-logo.svg';
import Socials from '../Socials/Socials';

export default function Header(): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  return (
    <Container sx={{ maxWidth: 1390 }} maxWidth={false}>
      <Grid container sx={{ height: 95 }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Box component="img" src={HeaderLogo} sx={{ width: 97 }} />
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            <Grid
              container
              sx={{
                '@media (max-width: 1025px)': { display: 'none' },
                '&': {
                  '> div': {
                    borderRight: 2,
                    borderColor: 'primary.main',
                    '&': {
                      ':last-of-type': {
                        borderRight: 0,
                      },
                    },
                  },
                  a: {
                    px: 2,
                    py: 0.75,
                    display: 'flex',
                    height: 54,
                    lineHeight: 1.33,
                    alignItems: 'center',
                    transition: 'all 0.2s ease-in-out',
                    textTransform: 'uppercase',
                    '&': {
                      strong: {
                        color: 'primary.main',
                        transition: 'all 0.2s ease-in-out',
                        transform: 'translateY(-8px)',
                      },
                      ':hover': {
                        backgroundColor: 'primary.main',
                        strong: {
                          color: 'primary.contrastText',
                          transform: 'translateY(0)',
                        },
                      },
                    },
                  },
                },
              }}
            >
              <Grid item>
                <Link href="#story">
                  <strong>Story</strong>
                </Link>
              </Grid>
              <Grid item>
                <Link href="#how-to-play">
                  <strong>How to play</strong>
                </Link>
              </Grid>
              <Grid item>
                <Link href="#roadmap">
                  <strong>Roadmap</strong>
                </Link>
              </Grid>
              <Grid item>
                <Link href="#faq">
                  <strong>FAQ</strong>
                </Link>
              </Grid>
              <Grid item>
                <Link href="#tokenomics">
                  <strong>Tokenomics</strong>
                </Link>
              </Grid>
              <Grid item>
                <Link href="#news">
                  <strong>News</strong>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ '@media (max-width: 1300px)': { display: 'none' } }}>
            <Socials />
          </Grid>
          <Grid item sx={{ '@media (max-width: 1025px)': { display: 'block' }, display: 'none' }}>
            <IconButton
              color="primary"
              sx={{ p: 0 }}
              onClick={() => {
                setDrawerOpen(true);
              }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <Box
                role="presentation"
                onClick={() => setDrawerOpen(false)}
                onKeyDown={() => setDrawerOpen(false)}
                sx={{ bgcolor: 'background.default', '& a': { justifyContent: 'center' }, px: 4 }}
              >
                <List>
                  <ListItem component={Link} href="#story">
                    Story
                  </ListItem>
                  <ListItem component={Link} href="#how-to-play">
                    How to play
                  </ListItem>
                  <ListItem component={Link} href="#roadmap">
                    roadmap
                  </ListItem>
                  <ListItem component={Link} href="#faq">
                    FAQ
                  </ListItem>
                  <ListItem component={Link} href="#tokenomics">
                    Tokenomics
                  </ListItem>
                  <ListItem component={Link} href="#news">
                    News
                  </ListItem>
                </List>
                <Socials size="small" />
              </Box>
            </Drawer>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              sx={{ minWidth: { xs: 100, sm: 157 }, lineHeight: { xs: 1.4, sm: 1.75 } }}
            >
              <strong>Enter</strong>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
