import React, { useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import NFT from '../../assets/images/nft.svg';

function NFTInstructionItem({
  title,
  content,
  selected,
}: {
  title: string;
  content: string;
  selected: boolean;
}): JSX.Element {
  return (
    <>
      <Typography
        sx={[
          { textTransform: 'uppercase', py: 1, px: 1.5, borderBottom: 3 },
          selected && { bgcolor: 'primary.main', color: 'primary.contrastText' },
        ]}
      >
        <strong>{title}</strong>
      </Typography>
      <Typography color="primary.light" sx={{ py: 1, px: 1.5, minHeight: { sm: 54, md: 95, lg: 136 } }}>
        {selected && content}
      </Typography>
    </>
  );
}

const nfts: { title: string; content: string }[] = [
  {
    title: 'Human',
    content: `
      Humans are the largest group of your spacecraft voyagers.
      They have been put into the cryosleep to survive the travel.
    `,
  },
  {
    title: 'Droid',
    content: `
      Humans are the largest group of your spacecraft voyagers.
      They have been put into the cryosleep to survive the travel.
    `,
  },
  {
    title: 'XYZ',
    content: `
      Some of the Origin cartriges was infected by XYZ - A.I. system developed to control your spacecraft.
      With XYZ NFT you get rewards from unsucessful Human hacking.
    `,
  },
];

function NFTInstructionList(): JSX.Element {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  return (
    <Grid container direction="column" sx={{ cursor: 'default' }}>
      {nfts.map((nft, index) => {
        return (
          <Grid item key={nft.title} onMouseEnter={() => setSelectedIndex(index)}>
            <NFTInstructionItem title={nft.title} content={nft.content} selected={selectedIndex === index} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default function IncubateYourNFTs(): JSX.Element {
  return (
    <Box sx={{ bgcolor: 'background.default', pt: 7 }}>
      <Container>
        <Typography variant="h3" sx={{ mb: 4.25 }}>
          Incubate your NFTs
        </Typography>
        <Grid container spacing={3.75} sx={{ mb: 3.75 }}>
          <Grid item>
            <Box sx={{ width: 167, height: 167, bgcolor: 'primary.light' }} />
          </Grid>
          <Grid item>
            <Box sx={{ borderLeft: 3, height: 1, width: 356 }}>
              <Typography sx={{ px: 1.5, py: 1, borderBottom: 3 }}>
                <strong>ORIGIN</strong>
              </Typography>
              <Typography color="primary.light" sx={{ fontWeight: 500, px: 1.5, py: 1, lineHeight: 1.66 }}>
                Origin is a medium used to encrypt information needed to unlock one of 50 000 escaped from planet Earth.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3.75} sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
          <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
            <Box sx={{ display: 'flex', height: 1, alignItems: 'flex-end' }}>
              <Box component="img" src={NFT} sx={{ width: { xs: 80, lg: 170 } }} />
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: { xs: 1, sm: 334, md: 446, lg: 558 },
                height: { xs: 301, sm: 361, md: 482, lg: 603 },
                bgcolor: 'primary.light',
              }}
            />
          </Grid>
          <Grid item sx={{ flex: 1 }}>
            <Box sx={{ height: 1, borderLeft: 3 }}>
              <NFTInstructionList />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
