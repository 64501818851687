import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

interface ArticleSummaryProps {
  title: string | JSX.Element;
  content: string | JSX.Element;
  last?: boolean;
}

function ArticleSummary({ title, content, last = false }: ArticleSummaryProps): JSX.Element {
  return (
    <Box sx={[{ p: 4, borderRight: { xs: 0, md: last ? 0 : 3 } }]}>
      <Grid container spacing={2.375} direction="column">
        <Grid item>
          <Box sx={{ height: 224, bgcolor: '#C7C7C7' }} />
        </Grid>
        <Grid item>
          <Typography sx={{ textTransform: 'uppercase' }}>
            <strong>{title}</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ fontWeight: 500 }}>{content}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function News(): JSX.Element {
  return (
    <Box sx={{ bgcolor: '#E7EDED', pt: 7, pb: 10, color: 'text.secondary' }} id="news">
      <Container>
        <Typography variant="h3" sx={{ mb: 4 }}>
          News
        </Typography>
        <Grid container sx={{ borderTop: 3 }}>
          <Grid item xs={12} sm={6} md={4}>
            <ArticleSummary
              title="Effective way to make rapid prototypes of pages"
              content={`
                Another difficulty with wireframes is that they don’t effectively display interactive details
                because they are static representations.
              `}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ArticleSummary
              title="Effective way to make rapid prototypes of pages"
              content={`
                Another difficulty with wireframes is that they don’t effectively display interactive details
                because they are static representations.
              `}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ArticleSummary
              last
              title="Effective way to make rapid prototypes of pages"
              content={`
                Another difficulty with wireframes is that they don’t effectively display interactive details
                because they are static representations.
              `}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
