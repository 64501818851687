import React from 'react';
import { Box, Container, styled, Typography } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails, { AccordionDetailsProps } from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { primaryColor, primaryLightColor } from '../../themes';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props}>
    {props.children}
  </MuiAccordion>
))(() => ({
  border: `3px solid ${primaryColor}`,
  fontWeight: 500,
  '&:not(:last-child)': {
    borderBottom: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => {
  return <MuiAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: primaryColor }} />} {...props} />;
})(() => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {},
}));

const AccordionDetails = styled((props: AccordionDetailsProps) => {
  return <MuiAccordionDetails {...props} />;
})(() => ({
  color: primaryLightColor,
}));

export default function FAQ(): JSX.Element {
  return (
    <Box sx={{ bgcolor: 'background.default', pt: 7, pb: 10 }} id="faq">
      <Container>
        <Typography variant="h3" sx={{ mb: 4 }}>
          FAQ
        </Typography>
        <Accordion>
          <AccordionSummary>What is a wallet and how can i create one?</AccordionSummary>
          <AccordionDetails />
        </Accordion>
        <Accordion>
          <AccordionSummary>How can i deposit BUSD from Binance to my wallet?</AccordionSummary>
          <AccordionDetails />
        </Accordion>
        <Accordion>
          <AccordionSummary>Why is Meta Ash team anonymous?</AccordionSummary>
          <AccordionDetails>
            The navigation design should communicate the relationship between the links it contains so that users
            understand the options they have for navigating the site.
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary>What I can find contract address for $ASH?</AccordionSummary>
          <AccordionDetails />
        </Accordion>
      </Container>
    </Box>
  );
}
