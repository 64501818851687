import React from 'react';
import { AppBar, Box, CssBaseline, Slide, ThemeProvider, useScrollTrigger } from '@mui/material';
import { Routes, Route } from 'react-router-dom';
import { defaultTheme } from './themes';
import Home from './pages/Home/Home';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

function HideOnScroll({ children }: { children: JSX.Element }): JSX.Element {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function App(): JSX.Element {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <HideOnScroll>
        <AppBar sx={{ boxShadow: 'unset', backgroundImage: 'unset' }}>
          <Header />
        </AppBar>
      </HideOnScroll>
      <Box sx={{ pt: '95px' }}>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}

export default App;
